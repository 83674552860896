import isEmpty from "lodash/isEmpty";
import { isPayPerUseSelector } from "./CommonBenefitSelectors";

export * from "./CommonBenefitSelectors";
export * from "./IncidentReportFormSelectors";
export * from "./ServiceRequestPayloadSelectors";

export const getAccessToken = (state) => state.accessToken;
export const getVinSaga = (state) => state.vinCapture;
export const getCurrentLocation = (state) => state.currentLocation;
export const getServiceDetails = (state) => state.serviceDetails.serviceDetails;
export const getServiceInfo = (state) => state.serviceInfo;
export const getServiceRequestInfo = (state) => state.serviceRequestInfo;
export const getServiceRequestPayload = (state) => state.serviceRequestPayload;
export const getServiceTime = (state) => state.serviceTime;
export const getPartnerDetails = (state) => state.partnerDetails.partnerDetails;
export const getPepSession = (state) => state.pepSession;
export const getCustomTowToDestinationList = (state) =>
  state.generalInfo.towToCustomDestinationList;
export const getGoogleDestinationsResult = (state) =>
  state.googleDestinationsResult;
export const getGoogleDetailsResult = (state) => state.googleDetailsResult;
export const partnerDetailsLoadedSelector = (state) =>
  state.partnerDetails.loading === false &&
  state.partnerDetails.error === false &&
  !isEmpty(state.partnerDetails.partnerDetails.experience);
export const getRoute = (state) => state.partnerDetails.partnerDetails.route;
export const getDisplayCode = (state) =>
  state.partnerDetails.partnerDetails.displayCode;
export const getRequestInfo = (state) => state.submitRequest;
export const getDestination = (state) => state.destination;
export const getPpuFuelPrice = (state) => state.generalInfo.ppuFuelPrice;
export const getPlaces = (state) => state.nearByDestinations.places;
export const getMakemodel = (state) => state.makemodel;
export const getDriverList = (state) => state.driverList;
export const hasPepSessionSelector = (state) =>
  isPayPerUseSelector(state) &&
  state.pepSession.success === true &&
  state.pepSession.session !== "";
export const getDuplicateRescue = (state) => state.generalInfo.duplicateRescue;
export const getTowReason = (state) =>
  state.serviceRequestPayload.towReason || "";
export const getAmazonConnectSelector = (state) =>
  getPartnerDetails(state)?.amazonConnect || {};
export const getTowDistance = (state) =>
  state?.serviceRequestPayload?.towDistance?.scalar || "";
export const getBusinessName = (state) =>
  state?.serviceRequestPayload?.businessName || "";
export const getPpuFlag = (state) =>
  getServiceRequestPayload(state)?.serviceRequest?.ppuFlag || "N";
export const getServiceTransition = (state) => state?.serviceTransition || {};
export const getFeatureFlags = (state) => state.featureFlag
export const getFeatureFlagValue = (state, flagName) => getFeatureFlags(state)?.[flagName]
export const getOmniStreamlinedFlag = (state) => getFeatureFlagValue(state, "omniStreamlined") || "";
export const getIsInitialized = (state) => getFeatureFlags(state).fetchState === "complete";
