import React, { Component } from "react";
import { connect } from "react-redux";
import TireTriage from "../../components/tire-triage";
import LockoutTriage from "../../components/lockout-triage";
import JumpStartTriage from "../../components/jumpStart-triage";
import TowTransition from "../../components/tow-transition";
import {
  generatePath,
  generatePathWithSearchParams
} from "./../../utilities/RedirectUtils";
import { datadogRum } from "@datadog/browser-rum";
import {
  updateServiceInfo,
  requestPartnerDetails,
  updateServiceTypeOptions,
  updateCurrentLocation,
  updateTowReason,
} from "../../action";
import { sendJacadaRequest } from "../../api";
import { FUEL_DELIVERY, TOW } from "../../app-consts/appConstants";
import FuelTriage from "../../components/fuel-triage";
import TowTriage from "../../components/tow-triage";
import {getOmniStreamlinedFlag} from "../../selector";

class Triage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionToTowService: false,
      isTrunk: "Lockout",
    };
  }

  componentDidMount() {
    document.title = "Triage | Roadside Assistance";
    this.initialization();
  }

  initialization = () => {
    const { submitRequest, commonBenefits } = this.props;
    if (submitRequest.success) {
      const { sessionId, callId, requestId, usageId } = submitRequest.response;
      if (!this.props.omniStreamlined && usageId) {
        this.props.history.push(
          generatePath(`tracker/${sessionId}/${callId}/${requestId}/${usageId}`)
        );
      } else if (!!this.props.omniStreamlined) {
        this.props.history.push(
          generatePathWithSearchParams(`/wd/l/${this.props.displayCode}/${callId}/${usageId}`)
        );
      }
    }
  };

  getServiceDetail = (type) => {
    return this.props.serviceDetails.serviceDetails.find(
      (o) => o.serviceText === type
    );
  };

  towType = (isTrunk) => {
    if (isTrunk) this.setState({ isTrunk: "Lockout-Trunk" });
  };

  updateSoftServiceDetails = (isToTow, type, options) => {
    if (!isToTow) {
      if (type === "Lockout") {
        this.props.updateServiceTypeOptions({
          lockoutType: "KeysInVehicle",
        });
      }
      if (type === "Tire Change") {
        this.props.updateServiceTypeOptions({
          tire: {
            vehicleType: "fourWheeler",
            gotSpare: options.gotSpare,
            replacementTire: options.selectedTire,
            visiblyDamaged: options.visiblyDamaged,
          },
        });
      }
      if (type === "Jump Start") {
        this.props.updateServiceTypeOptions({
          battery: {
            vehicleStoppedWhileDriving: false,
          },
        });
      }
      if (type === FUEL_DELIVERY) {
        this.props.updateServiceTypeOptions({ gas: options.fuelType });
      }
      this.props.updateServiceInfo(this.getServiceDetail(type));
      this.navigateScheduleService();
    } else {
      if (this.props.commonBenefits.experience?.towTriageEnabled) {
        this.props.updateTowReason(options?.towReason);
      }
      this.setState({
        transitionToTowService: true,
      });
    }
  };

  handleClickTowTransition = () => {
    this.props.updateServiceInfo(this.getServiceDetail("Tow"));
    let jacadaRequestData = {
      externalSystemId:
        localStorage.getItem("conversationId") || "not available",
      events: [
        {
          name: "svc_id",
          value: 45,
        },
        {
          name: "event_id",
          value: 52,
        },
      ],
    };
    sendJacadaRequest(jacadaRequestData);
    localStorage.setItem("serviceText", "Tow");
    this.navigateScheduleService();
  };

  navigateScheduleService = () => {
    const {
      partnerDetails: {
        partnerDetails: { experience },
      },
    } = this.props;
    if (experience?.schedulingEnabled) {
      this.props.history.push(generatePath("ScheduleService"));
    } else {
      this.props.updateCurrentLocation({ setFromLocationServices: false });
      this.props.history.push(generatePath("disablementlocation"));
    }
  };

  callCustomerService = () => {
    const { partnerDetails, commonBenefits } = this.props;
    datadogRum.addAction("ServiceTransition: Call for Assistance", {
      appId: partnerDetails.appId,
      memberId: commonBenefits.memberId,
    });
    const phone =
      partnerDetails.partnerDetails?.phone ||
      localStorage.getItem("callbackNumber");
    window.location.href = "tel:" + phone;
  };

  render() {
    let serviceName = localStorage.getItem("triage_type");
    let triageComponent;
    const { isTrunk } = this.state;

    switch (serviceName) {
      case "Tire Change":
        triageComponent = (
          <TireTriage
            updateSoftServiceDetails={this.updateSoftServiceDetails}
            partnerCode={this.props.displayCode}
          />
        );
        break;

      case "Lockout":
        triageComponent = (
          <LockoutTriage
            towScreenDispatcher={this.towType}
            updateSoftServiceDetails={this.updateSoftServiceDetails}
          />
        );
        break;

      case "Jump Start":
        triageComponent = (
          <JumpStartTriage
            updateSoftServiceDetails={this.updateSoftServiceDetails}
          />
        );
        break;
      case FUEL_DELIVERY:
        triageComponent = (
          <FuelTriage
            updateSoftServiceDetails={this.updateSoftServiceDetails}
          />
        );
        break;
      case TOW:
        triageComponent = <TowTriage />;
        break;
      default:
        break;
    }
    const towService = this.props.commonBenefits.services?.find(
      (service) => service.type === TOW
    );
    const towCoveredMiles = towService?.towIncludedQty;
    const towCoverageAmount = towService?.coverageAmount;

    return (
      <div role="main">
        {this.state.transitionToTowService ? (
          <TowTransition
            membership={this.props.commonBenefits.benefits}
            type={serviceName === "Lockout" ? isTrunk : serviceName}
            towCost={this.props.serviceDetails.serviceDetails[0].cost}
            towToEnabled={this.props.towToEnabled}
            towUnsupported={this.props.partnerDetails.partnerDetails.experience?.excludedServiceTypes?.includes(
              "Tow"
            )}
            displayCode={this.props.displayCode}
            onClickTowTransition={this.handleClickTowTransition}
            handleContinueWithSoftService={this.updateSoftServiceDetails}
            onClickCallCustomerService={this.callCustomerService}
            milesBased={
              !!this.props.partnerDetails.partnerDetails.experience?.milesBased
            }
            dollarBased={
              !!this.props.partnerDetails.partnerDetails.experience?.dollarBased
            }
            towCoveredMiles={
              !!towCoveredMiles && towCoveredMiles !== "N/A"
                ? towCoveredMiles
                : ""
            }
            towCoverageAmount={!!towCoverageAmount ? towCoverageAmount : ""}
          />
        ) : (
          triageComponent
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    serviceDetails: state.serviceDetails,
    accessToken: state.accessToken,
    partnerDetails: state.partnerDetails,
    towToEnabled: state.partnerDetails.partnerDetails.experience?.towToEnabled,
    etaDetails: state.etaDetails,
    commonBenefits: state.commonBenefits,
    submitRequest: state.submitRequest,
    displayCode: state.partnerDetails.partnerDetails.displayCode,
    omniStreamlined: getOmniStreamlinedFlag(state)
  };
}

export default connect(mapStateToProps, {
  updateServiceInfo,
  requestPartnerDetails,
  updateServiceTypeOptions,
  updateCurrentLocation,
  updateTowReason,
})(Triage);
