export const LOAD_FEATURE_FLAG = "@@FeatureFlags/LOAD_FEATURE_FLAG";
export const LOAD_FEATURE_FLAG_SUCCESS = "@@FeatureFlags/LOAD_FEATURE_FLAG_SUCCESS";
export const LOAD_FEATURE_FLAG_FAILED = "@@FeatureFlags/LOAD_FEATURE_FLAG_FAILED";

export function loadFeatureFlag(payload) {
  return {
    type: LOAD_FEATURE_FLAG,
    payload
  };

}
export function loadFeatureFlagSuccess(payload) {
  return {
    type: LOAD_FEATURE_FLAG_SUCCESS,
    payload
  };
}

export function loadFeatureFlagFailed(payload) {
  return {
    type: LOAD_FEATURE_FLAG_FAILED,
    payload
  };
}
