import {
  LOAD_FEATURE_FLAG,
  LOAD_FEATURE_FLAG_SUCCESS,
  LOAD_FEATURE_FLAG_FAILED,
  REFRESH_REDUCERS
} from "../action";

const initialState = {
  omniStreamlined: "",
  fetchState: "idle",
  error: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_FEATURE_FLAG:
      return {...state, fetchState: "pending"};
    case LOAD_FEATURE_FLAG_SUCCESS:
      return {...state, omniStreamlined: action.payload, fetchState: "complete"};
    case LOAD_FEATURE_FLAG_FAILED:
      return {...state, fetchState: "error", error: action.payload};
    default:
      return state;
  }
}
