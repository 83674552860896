import React from 'react'
import ReactDOM from 'react-dom'
import {Route, BrowserRouter} from 'react-router-dom'
import {Provider, useSelector} from 'react-redux'
import {getStore} from './getStore'
import App from './App'
import './styles/main.css'
import 'focus-visible/dist/focus-visible.js'
import { PersistGate } from 'redux-persist/integration/react'
import Spinner from "./components/spinner";

const {store, persistor} = getStore();
store.dispatch({ type: '@@App/INIT' }); // Dispatching INIT action to initialize sagas

if(window.location.hostname.includes('local') && window.location.pathname.includes('OmniAssist') && window.location.search.includes('conversationId')){
  window.allstateRDS.logger.log = () => {}
}



const App2 = () => {
  const { fetchState } = useSelector(state => state.featureFlag)
  if (fetchState === 'pending' || fetchState === 'idle'){
    return <Spinner/>
  }

  return <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
      <Route component={App}/>
    </BrowserRouter>
  </PersistGate>

}

ReactDOM.render(
  <Provider store={store}>
    <App2/>
  </Provider>, document.getElementById('root'));
