import React, { Component } from 'react';
import { connect } from 'react-redux';
import { datadogRum } from "@datadog/browser-rum";
import {
  updateCurrentLocation,
  updateScheduledServiceDateTime,
  updateServiceTime
} from '../../action';
import FormOption from '../../components/form-option';
import CarAnn from '../../components/svg/CarAnn';
import { generatePath } from '../../utilities/RedirectUtils'
import { LocaleConsumer } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import {
  sendScheduleServiceFlag
} from "../../jacada/sendJacadaEventData";
import {
  removeAdditionalField
} from "../../action/updateServiceRequestPayload-action";
import { Button } from 'mesh-component-library';
import {getOmniStreamlinedFlag} from "../../selector";

export class ScheduleService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceOption: 'HelpNow'
    }
  }

  componentDidMount() {
    datadogRum.addAction("User lands on scheduled service selection page");
    document.title = 'Schedule Service | Roadside Assistance';
    window.scrollTo(1, 1);

  }
  onContinueClick = (event) => {
    datadogRum.addAction("User clicked continue on scheduled service selection page");
    if (this.state.serviceOption === 'HelpNow') {
      this.props.updateCurrentLocation({ setFromLocationServices: false })
      this.props.history.push(generatePath('disablementlocation'));
      this.props.updateServiceTime("")
      this.props.removeAdditionalField("requestscheduledatetime")
      if (this.props?.omniStreamlined) {
        this.props.updateScheduledServiceDateTime({})
      }
    } else {
      sendScheduleServiceFlag();
      this.props.history.push(generatePath('service-time'));
    }
  }
  selectServiceOption = (event) => {
    datadogRum.addAction(`User selected  ${event.currentTarget.value} in on scheduled service selection page`)
    this.setState({ serviceOption: event.currentTarget.value });
  }
  render() {
    const {
      hasAllstateBrandHeader
    } = this.props;

    return (
      <LocaleConsumer>
        {(getTranslatedText) => (
          <div role="main" className={`l-container-scheduleservice static}`}>
            <CarAnn />
            <h1 id='when-can-we-help-you' className={`${hasAllstateBrandHeader && 'u-vr-3-top'} l-container-scheduleservice__hd u-vr-3 u-hr-2 u-hr-2-left`}>
              {getTranslatedText(TRANSLATION_CONSTANTS.WHEN_HELP_TEXT)}
            </h1>

            <div className="l-container-scheduleservice__bd u-vr-3 u-hr-2 u-hr-2-left">
              <div className="c-form-field">
                <FormOption
                  className="c-scheduleType-option"
                  onClick={(event) => {
                    this.selectServiceOption(event)
                  }}
                  onKeyDown={(event) => {
                    this.selectServiceOption(event)
                  }}
                  label={getTranslatedText(TRANSLATION_CONSTANTS.GET_HELP_NOW)}
                  value="HelpNow"
                  name="helpmechoose"
                  checked={this.state.serviceOption === 'HelpNow'}
                  id="helpmechoose"
                  type="radio" />

                <FormOption
                  className="c-scheduleType-option"
                  onClick={(event) => {
                    this.selectServiceOption(event)
                  }}
                  onKeyDown={(event) => {
                    this.selectServiceOption(event)
                  }}
                  label={getTranslatedText(TRANSLATION_CONSTANTS.SCHEDULE_LATER)}
                  checked={this.state.serviceOption === 'ScheduleLater'}
                  value="ScheduleLater"
                  name="schedulelaterchoose"
                  id="schedulelaterchoos"
                  type="radio" />
                {this.state.serviceOption === 'ScheduleLater' &&
                  <div className="compliance-align">
                    <label
                      dangerouslySetInnerHTML = {{__html: getTranslatedText(TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION_SCHEDULED_SERVICE)}}
                      data-testid = "compliance-message-scheduled">
                    </label>
                  </div>
                }
                <Button
                  size='lg'
                  hasUpgrade
                  id="schedule-service"
                  onClick={this.onContinueClick}
                  utils={{
                    fullWidth: true
                  }}>
                  {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                </Button>
               </div>
            </div>
          </div>
        )}
      </LocaleConsumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasAllstateBrandHeader: state.partnerDetails.partnerDetails.hasAllstateBrandHeader,
    omniStreamlined: getOmniStreamlinedFlag(state)
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentLocation: (data) => dispatch(updateCurrentLocation(data)),
  updateServiceTime: (data) => dispatch(updateServiceTime(data)),
  removeAdditionalField: (data) => dispatch(removeAdditionalField(data)),
  updateScheduledServiceDateTime: (data) => dispatch(updateScheduledServiceDateTime(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleService);
