import { useEffect, useState } from 'react';
import {isEmpty} from 'lodash';
import { connect } from 'react-redux';
import {generatePath} from '../utilities/RedirectUtils';
import {
  commonBenefitsStart,
  benefitsLookupStart,
  refreshReducerPartnerDetails,
  updateContactNumber,
  updateSessionId,
  updatePartnerDetailsPartnerCode,
  updateUsageId
} from '../action';
import useQuery from '../hooks/useQuery';
import {createMemberSessionOpenApi} from "../api";
import { cookieMatcher } from '../utils/cookieUtils';
import {sendAcDelcoEventToJacada} from '../jacada/sendJacadaEventData'
import CONSTANTS from '../app-consts/appConstants';
import {getIsInitialized, getOmniStreamlinedFlag} from "../selector";

const getSanitizedANI = (ANI) => {
  if (ANI) {
    let phone = ANI.replace(/\s/g, '');
    if (phone[0] === '1') {
      phone = phone.replace(phone[0], '');
    }
    return phone;
  }
  return undefined;
}

const mapDispatchToProps = (dispatch) => ({
  startCommonBenefitsLookup: (user) => dispatch(commonBenefitsStart(user)),
  startOmniBenefitsLookup: (user) => dispatch(benefitsLookupStart(user)),
  updateContactNumber: (phone) => dispatch(updateContactNumber(phone)),
  clearPreviousPartnerDetails: () => dispatch(refreshReducerPartnerDetails()),
  updateSessionId: (session) => dispatch(updateSessionId(session)),
  updateUsageId: (usage) => dispatch(updateUsageId(usage)),
  updatePartnerDetailsPartnerCode: (partnerCode) => dispatch(updatePartnerDetailsPartnerCode(partnerCode))
})

/**
 * CommonBenefits is a cross-cutting concern of the application. We need to know the customer's "benefits" status to be able to guide
 * them as well as determine what they are eligible for. In order to load the commonBenefits we need to first know the status of
 * the partnerDetails hence why we need to care if/when the partnerDetails are loading (see partnerCodeLoading).
 * @param partnerCodeLoading
 * @param partnerCode
 * @param startCommonBenefitsLookup
 * @param startOmniBenefitsLookup
 * @param primaryLookup
 * @param route
 * @param history
 * @param commonBenefitsPresent
 * @param commonBenefitsLoading
 * @param benefitsEnabled
 * @returns {null}
 * @constructor
 */
export const CommonBenefits = ({
  aniLookupEnabled,
  benefitsEnabled,
  lastSuccessfulSessionType,
  clearPreviousPartnerDetails,
  commonBenefitsLoading,
  commonBenefitsPartnerCode,
  commonBenefitsPresent,
  history,
  partnerCodeLoading,
  partnerDetails,
  primaryLookup,
  route,
  serviceRequestPayload,
  startCommonBenefitsLookup,
  startOmniBenefitsLookup,
  updateContactNumber,
  updateSessionId,
  updatePartnerDetailsPartnerCode,
  updateUsageId,
  omniStreamlined,
  initialized
}) => {
  const { displayCode, partnerCode, appId, experience, promoCode } = partnerDetails;
  const { ANI, jobId, conversationId, session, vin, odo, ars_token, usage } = useQuery();
  const phone = getSanitizedANI(ANI) || serviceRequestPayload?.serviceRequest.contactNumber;
  const phoneLookup = (phone && aniLookupEnabled);
  const vinLookup = !isEmpty(vin) && vin.length <= 17;
  const odoLookup = !isEmpty(odo);

  const saveJobIdAndConversationIdInLocalStorageIfExist = () => {
    if(!isEmpty(jobId)){
      localStorage.setItem('jobId', jobId);
    }

    if(!isEmpty(conversationId)){
      localStorage.setItem('conversationId', conversationId);
    }
  }

  const getVin = () => {
    if(vinLookup) {
      localStorage.setItem('vin', vin);
      return vin;
    }
  }

  const getMileage = () => {
    if(odoLookup) {
      localStorage.setItem('mileage', odo);
      return odo;
    }
  }

  if(ars_token) {
    localStorage.setItem('ars_token', ars_token);
  }


  const getSessionId = () => {
    //When loading the common benefits, make sure to set the session Id if it is available
    if (session) {
      localStorage.setItem('benefitSession', session);
      updateSessionId(session);
      return session;
    }
  }

  const getUsageId = () => {
    if (usage) {
      localStorage.setItem('usageId', usage);
      updateUsageId(usage);
      return usage;
    }
  }

  // TODO: candidate for extraction into a utility?
  const getMemberId = () => {
    if (route === 'benefits') {
      let cookieMatches = cookieMatcher();

      if (cookieMatches) {
        const memberId = JSON.parse(cookieMatches[2]).membershipId;
        localStorage.setItem('memberId', memberId);
        return memberId;
      } else {
        return undefined;
      }
    } else if (route === 'member') {
      // Never fetch from phone, always local storage if route is 'member'
      return localStorage.getItem('memberId');
    }
    if (phone) {
      localStorage.setItem('memberId', phone);
      updateContactNumber(phone);
      return phone;
    }
    return localStorage.getItem('memberId');
  }

  /**
   * Kicks off the start of the Common Benefits "flow".
   * Note that this doesn't happen until we successfully retrieve partner details.
   */
  const createLookupDetails = () => {
    let vin = getVin();
    let mileage = getMileage();
    let memberId = getMemberId();
    let sessionId = getSessionId();
    let usageId = getUsageId();
    // sessionType is derived based on display code & lastSuccessfulSessionType when partner switches
    let sessionType
    if (lastSuccessfulSessionType.includes("_")) {
      sessionType = `${displayCode}_${lastSuccessfulSessionType.split("_")[1]}`;
    } else {
      sessionType = `${displayCode}_${lastSuccessfulSessionType}`;
    }
    let lookupType = phoneLookup ? 'phoneLookup' : 'aopLookup';
    const lookupDetails = { phoneLookup, memberId, sessionType, sessionId, vin, mileage, usageId };
    switch (primaryLookup) {
      case 'memberlookup': {
        if (memberId) {
          if (!phoneLookup) {
            lookupType = 'memberNumber';
          }
          lookupDetails.memberId = memberId;
        } else {
          //TODO: investigate if logic is needed to handle if missing details in localstorage
          lookupType = 'firstNameLastNameZip';
          lookupDetails.details = {
            firstName: localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            zip: localStorage.getItem('zip'),
          }
        }
        break;
      }
      case 'partnerlogin': {
        const vin = localStorage.getItem('vin');
        const mileage = localStorage.getItem('mileage');
        if (vin && mileage) {
          lookupType = 'vinMileage';
          lookupDetails.formdata = {
            vin,
            mileage
          };
        } else {
          return history.push(generatePath(primaryLookup));
        }
        break;
      }
      case 'vinLookup': {
        const vin = localStorage.getItem('vin');
        if (vin) {
          lookupDetails.formdata = {
            vin
          }
        }
        break;
      }
      case 'vinOdoLookupForm': {
        const vin = localStorage.getItem('vin');
        const mileage = localStorage.getItem('mileage');

        if (sessionId) {
          lookupDetails.formdata = {
            sessionId
          }
        } else if (vin && mileage) {
          lookupDetails.formdata = {
            vin,
            mileage
          }
        } else {
          return history.push(generatePath('memberlookup'));
        }
        break;
      }
      case 'vinOdoTokenLookupForm': {
        const vin = localStorage.getItem('vin');
        const mileage = localStorage.getItem('mileage');

        if (sessionId) {
          lookupDetails.formdata = {
            sessionId
          }
        } else if (vin && mileage && ars_token) {
          lookupDetails.formdata = {
            vin,
            mileage,
            arsToken: ars_token
          }
          delete lookupDetails.memberId
          lookupDetails.arsToken = ars_token
        } else {
          return history.push(generatePath('memberlookup'));
        }
        break;
      }
      default:
          break;
    }

    saveJobIdAndConversationIdInLocalStorageIfExist();

    if (phone && aniLookupEnabled && !!omniStreamlined) {
      lookupDetails.lookupType = CONSTANTS.AUTO_PHONE_LOOKUP;
    } else if (usage && !!omniStreamlined)  {
      lookupDetails.lookupType = 'appointmentNumber'
      lookupDetails.searchCallId = sessionId
      lookupDetails.appointmentNumber = sessionId
    } else {
      lookupDetails.lookupType = lookupType;
    }

    if(lookupType === CONSTANTS.PHONE_LOOKUP || lookupType === CONSTANTS.AUTO_PHONE_LOOKUP || lookupType === CONSTANTS.PHONE_NUMBER) {
      // member id at this point is phone number (based on ANI)
      lookupDetails.searchBy = lookupDetails.memberId;
    }
    return lookupDetails;
  }

      // Fetch Omni or Open api benefits
  const loadCommonBenefits = () => {
    const lookupDetails = createLookupDetails();
    if (!!omniStreamlined) {
      startOmniBenefitsLookup(lookupDetails);
    } else {
      startCommonBenefitsLookup(lookupDetails);
    }
  }

  useEffect(() => {
    if (benefitsEnabled && initialized && !commonBenefitsPresent) {
      loadCommonBenefits();
    }
  }, [benefitsEnabled, initialized, !commonBenefitsPresent]);

  useEffect(() => {
    const loadMemberSession = async () => {
      if (experience && experience.passThruEnabled) {
        const options = {
          appId,
          partnerCode,
          type: "web-client",
          flippedToPpu: false
        };
        const values = new URLSearchParams(window.location.search);
        const isOmniAssist = route === 'OmniAssist';
        const sessionResponse = await createMemberSessionOpenApi(route,options);
        const sessionId = sessionResponse.data.id
        if(sessionId) {
          updateSessionId(sessionId)
          localStorage.setItem("benefitSession", sessionId)
        }
        if (isOmniAssist) {
          let jobId = values.get('jobId')|| ''
          sendAcDelcoEventToJacada(jobId, phone, promoCode, partnerCode)
        }
      }

    }
    loadMemberSession();
  }, []);

  useEffect(() => {
    const needsNewBenefits = benefitsEnabled && !commonBenefitsLoading && partnerCode && !partnerCodeLoading && !commonBenefitsPresent;
    if (needsNewBenefits) {
      loadCommonBenefits();
    }
  }, [partnerCodeLoading, commonBenefitsPresent, benefitsEnabled]);

  useEffect(() => {
    const needsNewPartnerDetails = commonBenefitsPartnerCode !== "" && commonBenefitsPartnerCode !== partnerCode;
    const shouldUpdatePartnerCodeOnly = partnerDetails.displayCode === 'oneapp' && commonBenefitsPartnerCode === 'AMC' && partnerCode === 'JJ';
    if(needsNewPartnerDetails && commonBenefitsPresent && !shouldUpdatePartnerCodeOnly) {
      clearPreviousPartnerDetails();
      history.push(generatePath('home',commonBenefitsPartnerCode));
    }
    if(shouldUpdatePartnerCodeOnly) {
      updatePartnerDetailsPartnerCode(commonBenefitsPartnerCode);
    }
  }, [commonBenefitsPresent])

  return null;
}

export default connect(
  (state) => ({
    aniLookupEnabled: state.partnerDetails.partnerDetails.experience?.aniLookupEnabled,
    benefitsEnabled: state.partnerDetails.partnerDetails.experience?.benefitsEnabled,
    lastSuccessfulSessionType: state.commonBenefits.lastSuccessfulSessionType,
    commonBenefitsLoading: state.commonBenefits.loading,
    commonBenefitsPartnerCode: state.commonBenefits.partnerCode,
    commonBenefitsPresent: state.commonBenefits.loading === false && state.commonBenefits.error === false,
    partnerCodeLoading: state.partnerDetails.loading,
    partnerDetails: state.partnerDetails.partnerDetails,
    primaryLookup: state.partnerDetails.partnerDetails.experience?.lookupMethods?.components.primary,
    route: state.partnerDetails.partnerDetails.route,
    serviceRequestPayload: state.serviceRequestPayload,
    omniStreamlined: getOmniStreamlinedFlag(state) ,
    initialized: getIsInitialized(state)
  }),
  mapDispatchToProps
)(CommonBenefits);
