import { connect } from "react-redux";
import { ServiceDetailsOverlay } from "./ServiceDetailsOverlay";
import {
  refreshServiceRequestInfo,
  selectedMakeModelYear,
  submitServiceRequest,
  updatedEpcsStatus,
  updatePpuFlag,
  updateLoggedInFlag,
  updateCCardInfo,
  updateServiceTypeOptions,
  updateVehicle,
  updateVehicleInfo,
  updatePpuFuelPrice,
} from "../../action";
import {
  hasPepSessionSelector,
  getIncidentReportFormComplete,
  getOmniStreamlinedFlag,
} from "../../selector";

const mapStateToProps = (state) => {
  const { passThruEnabled, serviceSelectionEnabled, customDestinationEnabled } =
    state.partnerDetails.partnerDetails.experience;
  const isPepPartner =
    passThruEnabled === false &&
    serviceSelectionEnabled === false &&
    customDestinationEnabled === false;

  return {
    commonBenefits: state.commonBenefits,
    serviceRequestPayload: state.serviceRequestPayload,
    serviceInfo: state.serviceInfo,
    vehicleValue: state.vehicleValue,
    destination: state.destination,
    makemodel: state.makemodel,
    submitRequest: state.submitRequest,
    partnerDetails: state.partnerDetails.partnerDetails,
    epcsStatus: state.epcsStatus,
    hasPepSession: hasPepSessionSelector(state),
    omniStreamlined: getOmniStreamlinedFlag(state),
    incidentReportFormComplete: getIncidentReportFormComplete(state),
    isPepPartner,
  };
};

export const ServiceDetailsOverlayContainer = connect(mapStateToProps, {
  updateVehicleInfo,
  submitServiceRequest,
  selectedMakeModelYear,
  updatedEpcsStatus,
  refreshServiceRequestInfo,
  updatePpuFlag,
  updateLoggedInFlag,
  updateCCardInfo,
  updateVehicle,
  updateServiceTypeOptions,
  updatePpuFuelPrice,
})(ServiceDetailsOverlay);
